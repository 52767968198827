@import 'Variables.scss';
.Titre {
  background-color: white;
  color: $color-text;
  padding: 20px 4%;
  margin-bottom: 40px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px;
  h1 {
    color: $color-text;
  }
}
.Fond {
  background-color: white;
  color: $color-text;
  padding: 20px 4%;
  margin-bottom: 40px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px;

  &.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    gap: 0px 30px;
  }
}
