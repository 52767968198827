@import 'Variables.scss';
:root {
  --primary: 237, 94%, 81%;
  --background: 266, 16%, 92%;
  --background-secondary: 256, 12%, 12%;
  --background-secondary-dark: 256, 10%, 10%;
  --background-secondary-light: 257, 11%, 16%;
  --text-primary: 0, 0%, 0%;
  /* Colors */
  --black: 0, 0%, 0%;
  --white: 0, 0%, 100%;
  --quite-gray: 0, 0%, 50%;
  --grooble: 10, 28%, 93%;
  /* Sizes */
  --heading-large: 5.6rem;
  --heading-medium: 3.6rem;
  --heading-small: 2.4rem;
  --paragraph: 1.11rem;
  --navbarJeunes-buttons: 2.4rem;
  /* misc */
  --transition-main: 0.175, 0.685, 0.32;
  /* Fonts */
  --font-main: 'Poppins';
}
.navbarJeunes {
  z-index: 300;
  padding: 20px;
  top: 0;
  right: 0;
  //padding: 0.5em 0;
  width: 5em;
  height: 100vh;
  position: fixed;
  background-color: hsl(var(--background-secondary));
  transition: width 0.35s cubic-bezier(var(--transition-main), 1);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  color: hsl(var(--quite-gray));
  .ant-flex {
    .jeunesOpen {
      display: none;

      .ant-collapse-item {
        display: block;
        overflow: hidden !important;
        width: 28em;
        .ant-collapse-header {
          color: hsl(var(--quite-gray)) !important;
          transition: all 0.35s cubic-bezier(var(--transition-main), 1);
          &:hover {
            color: hsl(var(--white)) !important;
          }
        }
      }
    }
    .jeunesClose {
      display: flex;
    }
  }

  &:hover {
    width: 28em;
    .ant-flex {
      .jeunesOpen {
        display: block;
      }
    }
    .jeunesClose {
      display: none;
    }
  }
  ul {
    li {
      .itemType,
      .itemAction {
        cursor: pointer;
        transition: all 0.35s cubic-bezier(var(--transition-main), 1);
        color: hsl(var(--quite-gray)) !important;
        &:hover {
          color: hsl(var(--white)) !important;
        }
      }

      .anticon {
        margin-right: 10px;
      }
      a {
        text-decoration: none;
        color: hsl(var(--quite-gray)) !important;
      }
    }
  }
}
