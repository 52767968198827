@import 'Variables.scss';
.notesCarnetLiaison {
  min-height: 100%;
  background-color: $gris-clair;
  padding: 20px 60px;
  .noteSeeMore {
    background-color: white;
    border-radius: 5px;
    height: 300px;
    $thickness: 5px;
    $duration: 2500;
    $delay: $duration/6;
    display: flex;
    justify-content: center;
    align-items: center;

    @mixin polka($size, $dot, $base, $accent) {
      background: $base;
      background-image: radial-gradient($accent $dot, transparent 0);
      background-size: $size $size;
      background-position: 0 -2.5px;
    }

    .bookshelf_wrapper {
      top: 20%;
      position: relative;
    }

    .books_list {
      margin: 0 auto;
      width: 300px;
      padding: 0;
    }

    .book_item {
      position: absolute;
      top: -120px;
      box-sizing: border-box;
      list-style: none;
      width: 40px;
      height: 120px;
      opacity: 0;
      background-color: white;
      border: $thickness solid $gris-fonce;
      transform-origin: bottom left;
      transform: translateX(300px);
      animation: travel #{$duration}ms linear infinite;

      &.first {
        top: -140px;
        height: 140px;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          height: $thickness;
          background-color: $gris-fonce;
        }

        &:after {
          top: initial;
          bottom: 10px;
        }
      }

      &.second,
      &.fifth {
        &:before,
        &:after {
          box-sizing: border-box;
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          width: 100%;
          height: $thickness * 3.5;
          border-top: $thickness solid $gris-fonce;
          border-bottom: $thickness solid $gris-fonce;
        }

        &:after {
          top: initial;
          bottom: 10px;
        }
      }

      &.third {
        &:before,
        &:after {
          box-sizing: border-box;
          content: '';
          position: absolute;
          top: 10px;
          left: 9px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: $thickness solid $gris-fonce;
        }

        &:after {
          top: initial;
          bottom: 10px;
        }
      }

      &.fourth {
        top: -130px;
        height: 130px;

        &:before {
          box-sizing: border-box;
          content: '';
          position: absolute;
          top: 46px;
          left: 0;
          width: 100%;
          height: $thickness * 3.5;
          border-top: $thickness solid $gris-fonce;
          border-bottom: $thickness solid $gris-fonce;
        }
      }

      &.fifth {
        top: -100px;
        height: 100px;
      }

      &.sixth {
        top: -140px;
        height: 140px;

        &:before {
          box-sizing: border-box;
          content: '';
          position: absolute;
          bottom: 31px;
          left: 0px;
          width: 100%;
          height: $thickness;
          background-color: $gris-fonce;
        }

        &:after {
          box-sizing: border-box;
          content: '';
          position: absolute;
          bottom: 10px;
          left: 9px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: $thickness solid $gris-fonce;
        }
      }

      &:nth-child(2) {
        animation-delay: #{$delay * 1}ms;
      }

      &:nth-child(3) {
        animation-delay: #{$delay * 2}ms;
      }

      &:nth-child(4) {
        animation-delay: #{$delay * 3}ms;
      }

      &:nth-child(5) {
        animation-delay: #{$delay * 4}ms;
      }

      &:nth-child(6) {
        animation-delay: #{$delay * 5}ms;
      }
    }

    .shelf {
      width: 300px;
      height: $thickness;
      margin: 0 auto;
      background-color: $gris-fonce;
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        @include polka(10px, 30%, white, $gris-fonce);
        top: 200%;
        left: 5%;
        animation: move #{$duration/10}ms linear infinite;
      }

      &:after {
        top: 400%;
        left: 7.5%;
      }
    }

    @keyframes move {
      from {
        background-position-x: 0;
      }

      to {
        background-position-x: 10px;
      }
    }

    @keyframes travel {
      0% {
        opacity: 0;
        transform: translateX(300px) rotateZ(0deg) scaleY(1);
      }

      6.5% {
        transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
      }

      8.8% {
        transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
      }

      10% {
        opacity: 1;
        transform: translateX(270px) rotateZ(0deg);
      }

      17.6% {
        transform: translateX(247.2px) rotateZ(-30deg);
      }

      45% {
        transform: translateX(165px) rotateZ(-30deg);
      }

      49.5% {
        transform: translateX(151.5px) rotateZ(-45deg);
      }

      61.5% {
        transform: translateX(115.5px) rotateZ(-45deg);
      }

      67% {
        transform: translateX(99px) rotateZ(-60deg);
      }

      76% {
        transform: translateX(72px) rotateZ(-60deg);
      }

      83.5% {
        opacity: 1;
        transform: translateX(49.5px) rotateZ(-90deg);
      }

      90% {
        opacity: 0;
      }

      100% {
        opacity: 0;
        transform: translateX(0px) rotateZ(-90deg);
      }
    }
  }
  .noteCarnetLiaison {
    background-color: white;
    color: $color-text;
    padding: 20px 4%;
    margin-bottom: 40px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px;
    &__header {
      padding: 0px 0px;
      color: $secondary;
      &__top {
        font-size: 1.5em;

        .InputListe {
          font-size: 11px !important;
          &__items {
            &__item {
              font-size: 11px !important;
            }
          }
        }
      }
      &__center {
        font-size: 0.9em;
      }
      &__bottom {
        font-size: 0.9em;
        margin-bottom: 1em;
      }
    }
    &__body {
      color: $gris-fonce;
      &__header {
        font-size: 2em;

        margin-bottom: 1em;
      }
      &__body {
      }
    }
    &__footer {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $gris-clair;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr;
      gap: 0px 0px;

      &__left {
        color: $secondary;
        display: flex;
        align-items: center;
      }
      &__right {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-left: 20px;
          font-size: 1.5em;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          color: $gris-clair;
          &:hover,
          &.active {
            color: $gris-fonce;
          }
        }
      }
    }
  }
}
