@import './Variables.scss';
.AccesRefuse {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: xx-large;
  color: $gris;
  h1 {
  }
  i {
    font-size: 200px;
  }
}
