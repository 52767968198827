.AbsencesEncodage {
  table {
    thead {
      tr {
        th {
          writing-mode: vertical-rl; /* Orientation verticale du texte */
          transform: rotate(180deg); /* Inverser l'orientation de bas en haut */
          /* Centrer le texte */
        }
      }
    }
    .ant-table-cell {
      padding: 5px !important;
    }
    .periode_0 {
      background-color: #e3ffff !important;
    }
    .periode_1 {
      background-color: #fffae3 !important;
    }
    .periode_2 {
      background-color: #e4ffe9 !important;
    }
    .periode_3 {
      background-color: #ffe3f6 !important;
    }
    .periode_4 {
      background-color: #f0f0f0 !important;
    }
    .periode_5 {
      background-color: #f8ffe2 !important;
    }
  }
}
