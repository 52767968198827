$gris: #6a778e;
$gris-clair: #d8d8d8;
$gris-fonce: #545454;
$primary: #646975;
$color-text: #6a778e;
$secondary: #999;

$borderRadius: 10px;
$spacer: 13px;
$linkHeight: $spacer * 3.5;
$timing: 250ms;
$transition: $timing ease all;
