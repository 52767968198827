@import 'Variables.scss';
.CarnetDeLiaisonEncodage {
  min-height: 100%;
  background-color: $gris-clair;
  padding: 20px 60px;
  .commentaire__box {
    .ql-toolbar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.88);
    }
    .ql-container {
      border: 1px solid #d9d9d9;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.88);
    }
  }
}
