@import 'styles/Variables.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $gris-clair;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.fondPage {
  min-height: 100%;
  background-color: $gris-clair;
  padding: 20px 60px;
}
.page {
  background-color: white;
  padding: 2em;
  border-radius: 5px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: $color-text;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 2px -2px;
}
.ck-editor__editable_inline {
  border-color: #d9d9d9 !important;
  border-radius: 0px 0px 8px 8px !important;

  ul,
  ol {
    margin: 0px 25px !important;
  }
}
.ck-sticky-panel__content {
  border-color: #d9d9d9 !important;
  border-radius: 8px 8px 0px 0px !important;
}
.ck-toolbar_grouping {
  background: none !important;
}
