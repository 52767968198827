.ant-modal-body {
  .modal-body {
    .two_lines {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 0px 20px;
    }
  }
}
